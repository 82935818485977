// 7.77 rem = 140px
.padding__xl {
  padding-top: 7.77rem;
  padding-bottom: 7.77rem;
  // 5.55rem = 100px
  &--lower-bottom {
    padding-bottom: 5.55rem;
  }
}
.padding__lg {
  padding-top: 6.66rem;
  padding-bottom: 6.66rem;
  &--lower-bottom {
    padding-bottom: 6rem;
  }
}
// 5rem - 90px
.padding__md {
  padding-top: 5rem;
  padding-bottom: 5rem;
  &--lower-bottom {
    padding-bottom: 4.55rem;
  }
}



@media (max-width: 992px) {
  .padding__xl {
    padding-top: 5.25rem;
    padding-bottom: 3.66rem;
    &--lower-bottom {
      padding-bottom: 5rem;
    }
  }
  .padding__lg {
    padding-top: 4.75rem;
    padding-bottom: 4.3rem;
    &--lower-bottom {
      padding-bottom: 3.6rem;
    }
  }
  // 5rem - 90px
  .padding__md {
    padding-top: 4rem;
    padding-bottom: 3.5rem;
    &--lower-bottom {
      padding-bottom: 3.2rem;
    }
  }
  .px-0-lg{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .pt-0-lg {
    padding-top: 0px;
  }
  .pb-1-lg{
    padding-bottom: 1rem;
  }
  .pb-0-lg {
    padding-bottom: 0px;
  }

}
@media (max-width: 768px) {
  .px-0-md{
    padding-left: 0px;
    padding-right: 0px;
  }
  .pt-0-md {
    padding-top: 0px;
  }
}
