@import "nice-select.scss";
@import "variables.scss";
@import "custom.scss";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "mixins.scss";
@import "header.scss";
@import "swiper.scss";
@import "fonts.scss";
@import "margins.scss";
@import "paddings.scss";
@import "shortcuts.scss";
@import "elements.scss";
@import "slickSliders.scss";
@import "about.scss";
@import "otherPages.scss";
@import "login.scss";
@import "registration.scss";
@import "modal.scss";
@import "articleSlider.scss";

.connector {
  position: relative;
  z-index: -2;
}
html {
  scroll-behavior: smooth;
}
.numbers {
  > div {
    $padding: 120px;
    > div:nth-of-type(4n - 1),
    > div:nth-of-type(4n) {
      padding-left: $padding;
    }
    > div:nth-of-type(4n - 2),
    > div:nth-of-type(4n - 3) {
      padding-right: $padding;
    }
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__number {
    height: 225px;
    width: 225px;
    background-image: url("../img/shape.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 66px;
    font-size: 3.33rem;
    letter-spacing: -0.16rem;
  }
}
.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &--3 {
    -webkit-line-clamp: 3;
  }
  &--4 {
    -webkit-line-clamp: 4;
  }
  &--5 {
    -webkit-line-clamp: 5;
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}
.trainings {
  .low-img {
    height: 415px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    > .container {
      height: 100%;
    }
  }
}
.breadcrumb-wrapper {
  background-color: $primary;
  .row {
    height: 3.125rem;
    align-items: center;
    padding-bottom: 2px;
  }
  * {
    color: white;
  }
  .breadcrumb {
    margin-bottom: 0;
    flex-wrap: wrap;
    display: flex;
    list-style: none;
    .breadcrumb-item {
      display: inline-flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: 15px;

      &:nth-of-type(n + 2) {
        padding-left: 20px;
        &::before {
          content: ">";
          padding-right: 20px;
          font-size: 14px;
          font-family: Noto Sans, sans-serif;
          font-weight: 300;
          line-height: 1;
          display: inline-block;
          color: #9d9d9c;
        }
      }
    }
    .active {
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      color: $primary;
    }
  }
}
@media (max-width: 991px) {

  .breadcrumb-wrapper .breadcrumb {
    display: none;
  }
}
.checkbox-l {
  p {
    margin-bottom: 0px;
  }
}
/* Track */
::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
  &:hover {
    width: 20px;
  }
}
.big-brand {
  padding: 4rem 4rem 4.3rem 4rem;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.senary-bg {
    img {
      transform: translateX(-18px);
    }
  }
  a {
    margin-top: auto;
  }
  img {
    max-height: 128px;
    max-width: 327px;
  }
  li {
    margin-bottom: 16px;
  }
}

@media (max-width: 1439.9px) {
  .big-brand {
    padding: 4rem 3rem 4.3rem 3.2rem;
  }
}
@media (max-width: 991.92px) {
  .numbers {
    &__item {
      flex-direction: column;
      margin-bottom: 40px;
    }
    &__number {
      width: 150px;
      height: 150px;
      margin-right: 0px;
      margin-bottom: 20px;
    }
    &__text {
      text-align: center;
    }
  }
}
@media (max-width: 575.9px) {
  .numbers {
    &__item {
      padding: 0px !important;
    }
    > div {
      > div {
        padding: initial;
      }
    }
  }
  .big-brand {
    padding: 4rem 2rem 4.3rem 2.2rem;
  }
}

.drawer{
  position: fixed;
  top: 50vh;
  transform: translateY(-50%);
  z-index: 100;
  list-style-type: none;
  padding: 0;
  right: 0;
  .simplex, .profix{
    transition: 0.3s;
    width: 50px;
    position: relative;
    right: -5px;
    div{

    a{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 15px;
      padding-right: 20px;
      padding-block: 5px;
      height: 150px;
      margin-bottom: 5px;
      img{
        width: 25px;

      }
    }
    }

  }:hover{
      right: 0px;
    }

    .simplex{
        background-color: #e99e06;
    }
    .profix{
      background-color: #005d9f;
    }

}

.brands{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  gap:  10px;
  img{
    height: 22px;
    padding: 0px;
  }
  a{
    width: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 0px;
  }
}

@media (max-width: 991px) {
  .drawer{
    display: none;
  }
}
@media (min-width: 992px) {
  .brands{
    display: none;
  }
}
