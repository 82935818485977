$primary: #00345d;
$secondary: #2d8fc7;
$tertiary: #42b2f2;
$quaternary: #9ed7f8;
$quinary: #9ed7f8;
$senary: #005d9f;

$rem: 18px;

$gray-color: #9a9a9a;
$gray-secondary-color: #f7f7f7;
$complementary-color: #ea9f08;
$complementary-secondary-color: #ffed00;

.senary-bg {
  background-color: $senary;
}
.complementary-bg {
  background-color: $complementary-color;
}

$container-max-widths: (
  xl: 1164px,
  xxl: 1400px,
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
) !default;

$btn-border-radius: 0px !default;
$btn-border-width: 2px !default;
$btn-font-weight: bold !default;

$form-check-input-checked-bg-color: white;
$form-check-input-border: 2px solid white;
$form-check-input-focus-border: $form-check-input-border;
$form-check-input-focus-box-shadow: 0px;
// $form-check-input-checked-bg-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#005d9f' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");
// $form-check-radio-checked-bg-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='#005d9f' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");
$form-check-input-width: 15px;
$form-check-padding-start: 15px;
$form-check-margin-bottom: 0px;
$form-check-input-border-radius: 0em;

$form-check-radio-border-radius: 0%;
$form-check-inline-margin-end: 2rem;

$form-select-border-color: $primary;
$form-select-border-radius: 0px;
$form-select-color: $gray-color;
$form-select-indicator: none;

$transition: $complementary-color;

$header-height: 137px;
$header-height-mobile: 87px;
$mobile-toolbar: 57px;

$font-weight-bolder: 500;

$input-border-width: 0px !default;
$input-font-size: 0.78rem !default;

$input-color: $primary;
$input-focus-width: 0px !default;
$input-focus-color: $primary !default;
$input-focus-color-opacity: 1 !default;
$input-focus-blur: 0px !default;
$input-focus-box-shadow: 0px !default;
$input-bg: transparent !default;
$input-border-color: $primary;
$input-padding-x: 0px !default;
$input-padding-y: 13px;

$btn-close-white-filter: grayscale(100%);
$btn-close-focus-shadow: 0px;

$btn-close-width: 1.05rem;
$btn-close-color: $primary;
$btn-close-opacity: 1;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}' stroke='0' ><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z' /></svg>");
$btn-close-height: $btn-close-width;
$modal-md: 488px;
$modal-content-border-radius: 0px;

$zindex-modal: 1000000;
$zindex-modal-backdrop: 100000;

$accordion-button-active-bg: transparent;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: transparent;
$accordion-button-active-color: $primary;
$accordion-border-color: transparent;
$accordion-border-radius: none;
$accordion-bg: transparent;
$accordion-icon-width: calc((30 / #{$rem}) * 1rem);