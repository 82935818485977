.section__bg {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -2;
  &--gray {
    background-color: #f6f6f6;
  }
}
.senary-bg {
  background-color: $senary;
}
.complementary-bg {
  background-color: $complementary-color;
}
.bg-gray {
  background-color: #f6f6f6;
}
.h-full{
  height: 100%;
}
.-transform-x-50{
  transform: translateX(-50%);
}
.padding-thing {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  & > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
.text-decoration-none {
  * {
    text-decoration: none;
  }
}

.hover-left{
  transition: .3s;
  &:hover{
    transform: translateX(-2px);
  }
}
.z-100{
  z-index: 100;
}
.hover-right{
  transition: .3s;
  &:hover{
    transform: translateX(-2px);
  }
}
.big-click-relative{
  position: relative;
  &::before{
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
  }
}
.text-hover{
  transition: .3s;
  &:hover{
    color: $primary;
    *{
      color: $primary;
    }
  }
}
.big-click{
  &::before{
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
  }
}
.g-16 {
  --bs-gutter-x: 0.88rem;
  --bs-gutter-y: 0.88rem;
}
.color-primary {
  color: $primary;
}
.color-black {
  color: black;
}
.color-gray {
  color: #9a9a9a;
}
.ls-05{
  letter-spacing: -.5px;
}
.fw-semibold {
  font-weight: 600;
}
.lh-24 {
  line-height: 1.33rem;
}
.lh-1{
  line-height: 1 !important;
}
.ls-06 {
  letter-spacing: 0.6px;
}
.padding-thing {
  position: relative;
  width: 100%;
  padding-bottom: 56.25% !important;
  &.padding-thing--low {
    padding-bottom: 30% !important;
  }
  &.padding-thing--square {
    padding-bottom: 100% !important;
  }
  &.padding-thing--medium-square {
    padding-bottom: 89% !important;
  }
  & > * {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.zoom {
  overflow: hidden;
  img {
    transition: 1.5s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &:hover img {
    transform: scale(1.04);
  }
  &--less {
    &:hover img {
      transform: scale(1.02);
    }
  }
  &--not-cut {
    img {
      transform: scale(0.96);
    }
    &:hover img {
      transform: scale(1);
    }
  }
}
.fb-initial{
  flex-basis: initial !important;
}
@media (min-width: 1200px) {
  .w-xl-75 {
    width: 75%;
  }
}
@media(min-width:992px){
  .h-lg-full{
    height: 100%;
  }
}
@media (max-width: 991.9px) {
  .padding-thing {
    &.padding-thing--low {
      padding-bottom: 56.25% !important;
    }
  }
}
@media (max-width: 575.9px) {
  .padding-thing {
    &.padding-thing--low {
      padding-bottom: 100.25% !important;
    }
  }
  .w-sm-100 {
    width: 100% !important;
  }
}
