// .box-phone{
// 	height: 100vh;
// }
// .row{
//     height: 100vh;
// }

.form-box {
  width: 470px;
  max-width: 90%;
  margin: auto;
}
.img-bg {
  background-image: url("../img/farba3.png");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  height: 100%;
}
.img-logo{
  max-width: 220px;
}
.forgot {
  font-size: 0.7em;
  font-style: italic;
}
.login-box{
  max-width: 469px;
  width: 100%;
}
.account-login {
  width: 220px;
  font-size: 0.7em;
  font-style: italic;
}

///////////////////////////////////////////////// MODAL////////////////////////////////////
.forgot{
  .btn{
    text-transform: none;
    min-width: 0px !important;
  }
}
.modal-content-save {
  // height: 80vh;
  padding: 20px;
  padding-bottom: 64px;
}
.modal-box-save {
  padding: 0px 0;
  .form-control {
    padding: 21px 0px 11px;
  }
}

.wrapper-1 {
  overflow: auto;
}
.modal-border-img-login {
  width: calc(100% + 40px);

  height: 24px;
  background-image: url("../img/farba2.jpg");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  margin-top: -20px;
  margin-left: -20px;
}

.modal-content-sharp {
  border-radius: unset;
}
.modal-content-save.modal-training {
  padding-top: 0px;
  .modal-header-save {
    padding: 20px 44px 0px;
  }
  .modal-border-img {
    height: 30px;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
// Modal END

.registration-text {
  text-decoration: none;
}

// Play in Hause
@media (max-width: 576px){
  .form-box {
    max-width: 68%;
  }
}
@media (max-width: 450px) {
  .modal-content-save.modal-training .modal-header-save {
    padding: 20px 15px 0;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  h3 {
    font-size: 20px;
  }
}

@media (min-width: 375px) and (max-width: 576px) {
  .box-phone {
    min-height: 100vh;
  }
  h3 {
    font-size: 25px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .box-phone {
    min-height: 100vh;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .box-phone {
    min-height: 100vh;
  }
}
