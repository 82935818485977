@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap");
html {
  font-size: 18px;
  
}
$rem: 18;
body {
  font-family: "IBM Plex Sans", sans-serif;
  color: $primary;
}
h1 {
  font-size: 2rem;
  font-weight: 400;
  .h2 {
    font-size: 2.44rem;
    font-weight: 200;
    margin-bottom: 3.33rem;
  }
  .h3{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  .h4 {
    font-size: 1.77rem;
    margin-bottom: 1.1rem;
  }
}
h2 {
  font-size: 2.44rem;
  font-weight: 200;
  margin-bottom: 3.33rem;
}
h3 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 2rem;
  .h5 {
    font-size: 1.33rem;
    margin-bottom: 1.5rem;
  }
}
h4 {
  font-size: 1.77rem;
  margin-bottom: 1.1rem;
}
h5 {
  font-size: 1.33rem;
  margin-bottom: 1.5rem;
}
a {
  transition: 0.25s;
  text-decoration: underline;
  text-decoration-color: transparent;
  &:hover {
    color: inherit;
    text-decoration-color: $primary;
  }
}

p {
  font-size: 0.94rem;
  color: black;
}
.fz-44 {
  font-size: calc((44 / #{$rem}) * 1rem);
  line-height: calc((49.28 / #{$rem}) * 1rem);
  letter-spacing: calc((-0.66px / #{$rem}) * 1rem);
  * {
    font-size: calc((44 / #{$rem}) * 1rem);
    line-height: calc((49.29 / #{$rem}) * 1rem);
    letter-spacing: calc((-0.66px / #{$rem}) * 1rem);
  }
}
.fz-24 {
  font-size: 1.33rem;
  * {
    font-size: 1.33rem;
  }
}
.fz-22 {
  font-size: calc((22 / #{$rem}) * 1rem);
  * {
    font-size: calc((22 / #{$rem}) * 1rem);
  }
}
.fz-20 {
  font-size: calc((20 / #{$rem}) * 1rem);
  * {
    font-size: calc((20 / #{$rem}) * 1rem);
  }
}
.fz-17 {
  font-size: 0.94rem;
  * {
    font-size: 0.94rem;
  }
}
.fz-14 {
  font-size: 0.78rem !important;
  * {
    font-size: 0.78rem !important;
  }
}
.fz-12 {
  font-size: 0.66rem;
  * {
    font-size: 0.66rem;
  }
}
@media(max-width: 1399px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  html {
    font-size: 15px;
  }
}
