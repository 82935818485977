.header {
  position: fixed;
  width: 100%;
  z-index: 100000 !important;
  background-color: white;
  transition: 0.8s;
  &.hide {
    transform: translateY(-100%);
    .dropdown-menu.show {
      display: none;
    }
  }
  &__container {
    flex-wrap: wrap;
  }
  li {
    list-style: none;
  }
  a {
    font-weight: 500;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      height: 3px;
      width: 100%;
      background-color: transparent;
      transition: 0.3s;
      left: 0px;
      bottom: -3px;
      border-radius: 4px;
    }
    &:hover::after,
    &.selected::after {
      background-color: #42b2f2;
    }
  }
  &__logo {
    height: 87px;
    display: flex;
    align-items: center;
    img {
      max-width: 164px;
    }
    a:after {
      display: none;
    }
  }
  .context-menu {
    a {
      &::after {
        bottom: 0px;
        height: 2px;
      }
    }
  }
  &__language {
    position: relative;
    .dropdown-menu {
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px;
      top: 1px !important;
      right: -1.9rem !important;
      left: inherit !important;
      min-width: initial !important;
      flex-direction: column;
      border: 0px;
      flex-wrap: wrap;
      &.show {
        display: flex;
      }
      li {
        padding: 6px 5px;
        background-color: white;
        span {
          color: $primary;
          font-size: 0.94rem;
        }
      }
      a {
        justify-content: space-between;
      }
    }
    button {
      font-size: 0.94rem !important;
      width: auto !important;
      background: transparent;
      font-weight: 600 !important;
      border: 0px;
      color: $primary !important;
      padding: 0px 4px !important;
      text-decoration: none !important;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -3px;
      height: 3px;
      width: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      background-color: #42b2f2;
      transition: 0.3s;
    }
    &:hover {
      &::after {
        background-color: $primary;
      }
      button {
        background-color: transparent !important;
        color: $primary;
        text-decoration: none;
        &.big-click {
          text-decoration: none;
        }
      }
    }
  }
  &__social {
    width: 133px;
    display: flex;
    justify-content: space-between;
    margin-left: 52px;
    flex-shrink: 0;
    a {
      display: flex;
      align-items: end;
    }
  }
  &__nav {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 101vw;
      background-color: $primary;
      height: 100%;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
    &--upper {
      a::after {
        display: none;
      }
    }
    &--upper::after {
      display: none;
    }
  }
  &__nav--lower {
    width: 100%;
    height: 50px;
    ul {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0px;
      height: 100%;
      padding-left: 0px;
      padding-bottom: 2px;
      li {
        display: flex;
        // flex-basis: 12%;
        // justify-content: center;
        @media (min-width: 992px){
          flex-basis: 12%;
          justify-content: center;
        }
        a {
          color: white;
          font-weight: 400;
        }
      }
    }
  }
  &__social {
    position: absolute;
    transform: translateX(100%);
    right: -53px;
  }
}
.search {
  display: flex;
  border: 0px;
  border-bottom: 2px solid $primary;
  max-width: 14.25rem;
  width: 100%;
  margin: 0px 3rem;
  // padding-bottom: 2px;
  position: relative;
  top: 1px;
  transition: 0.3s;
  &.active {
    border-color: #42b2f2;
    .btn {
      svg {
        color: #42b2f2;
      }
    }
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bebebe;
    }
  }
  &__input {
    border: 0px;
    width: 100%;
    &::placeholder {
      transition: 0.3s;
    }
    &:focus-visible {
      outline: none;
    }
  }
  button {
    padding: 0.6rem;
    width: auto;
    border: 0px;
    svg {
      width: 20px;
      height: 20px;
      transition: 0.3s;
    }
  }
}
.hamburger {
  display: none;
}
.open-context .ms-auto.d-flex.relative.d-lg-none {
  z-index: 10;
}
.context-menu-opener {
  // filter: brightness(0%) invert(1);
  // transform: rotate(-90deg) translateX(-50%) translateY(-25px) scale(.5);
  cursor: pointer;
  padding: 65px;
  z-index: 10;
  position: absolute;
  right: -44px;
  top: 50%;
  transform: translateY(-50%) scale(0.4);
  transition: 0.3s;
  &.opened {
    transform: translateY(-50%) scale(0.4) rotate(180deg);
  }
}
.context-menu {
  display: none;
  .menu-2,
  .menu-3,
  .menu-4 {
    display: none;
  }
  a {
    display: inline-block;

    padding: 0.7rem 1rem 0.6rem;
    width: 100%;
  }
}
.menu-1{}
.menu-item {
  transition: 0.5s;
}
@media (max-width: 1800px) {
  .header__social {
    position: static;
    transform: none;
  }
}
@media (max-width: 1440px) {
  .search {
    max-width: 12rem;
  }
}

@media (min-width: 992px)
{
  .menu-1{
    position: absolute !important;
  }
}



@media (max-width: 991.9px) {
  .header__nav--lower { 

      ul
      {
    //height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
      }
    }


  .header__nav--lower a:hover {
    &::after {
      display: none;
    }
  }


  .header {
    position: fixed;
    z-index: 2;
    top: 0px;
    transform: translateX(-100vw);
    nav {
      transition: 1s;
    left: 0px;
    }
    a::after {
      width: 31%;
      bottom: 10px;
    }
    &.hide {
      // transform: translatey(0%);
      transform: translate(-100vw, -100%);
      // top: -$header-height-mobile;
    }
    &__container {
      height: 100vh;
      > a {
        transform: translateX(100vw);
      }

      &--active {
        // max-height: initial;

        nav {
          left: 100vw;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }
    &__logo {
      transform: translateX(calc(100vw - 18px));
      padding-left: 18px;
      width: calc(100vw + 11px);
      background-color: white;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 110vw;
        left: 0px;
        top: 0px;
        background-color: white;
        height: 100%;
        z-index: -1;
      }
    }
    &__nav {
      width: 275px;
      margin-right: 223px;
      transition: 1s;
      &--upper,
      &--lower {
        a {
          width: 100%;
          font-weight: 500 !important;
        }
        li {
          width: 100%;
        }
        &::after {
          display: block;
          width: calc(100% + 25px);
          left: -25px;
          transform: none;
          height: calc(100% + 2px);
        }
      }
      &--lower {
        min-height: calc(65% - 43px);
        padding-bottom: 30px;
        li {
        // max-height: 47px;
        }
      }
      &--upper {
        min-height: calc(35% - 43px);
      }
      &--upper,
      &--lower ul {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 5px;
        a {
          padding-top: 10px;
          padding-bottom: 10px;
          color: white;
         // height: 47px;
          display: inline-block;
        }
      }
      &--lower {
        order: 1;
        height: calc(50% - 43px);
        padding-top: 20px;
        margin-top: 2px;
        li {
          flex-direction: column;
        }
        .context-menu {
          height: 100%;
          width: 100%;
          transform: translateX(0);
          .menu-2,
          .menu-3,
          .menu-4 {
            position: static !important;
            border-bottom: 1px solid white;
            &.active {
              display: flex;
            }
          }
          .menu-2 {
            font-size: 0.9rem;
          }
          .menu-3 {
            font-size: 0.82rem;
            display: none;
          }
          .menu-4 {
            font-size: 0.72rem;
            display: none;
          }
        }
        ul {
          align-items: flex-start;
          justify-content: start;
          overflow-y: scroll;
          overflow-x: hidden;
        }
      }
      &--upper {
        order: 2;
        justify-content: end;
        padding-bottom: 50px;
        ul {
          position: static;
        }
      }
    }

    &__social {
      margin-left: 0px;
      padding-top:  0px;
      position: static;
      transform: translateX(0px);
      width: 230px;
      img {
        filter: brightness(0%) grayscale(100%) invert(100%);
      }
    }
  }
  .menu-3 {
    border-bottom: 0px !important;
  }
  .search-opener {
    svg {
      transition: 0.3s;
    }
    &.active {
      svg {
        color: #42b2f2;
      }
    }
  }
  .header__language {
    position: fixed;
    right: calc(-100vw + 62px);
    top: calc((87px - 46px) / 2);
    &::after {
      bottom: 7px;
      height: 2px;
    }
    .btn {
      min-width: auto;
    }
  }

  .search-opener,
  .hamburger {
    $height: 20px;
    display: flex;
    flex-direction: column;
    height: $height;
    position: fixed;
    top: calc((#{$header-height-mobile} - #{$height}) / 2);
    transform: translateX(100vw);
    left: calc(100vw - 39px);
    justify-content: space-between;
    cursor: pointer;
    span {
      height: 2px;
      position: relative;
      width: 28px;
      transition: 0.3s;
      transform-origin: 1px center 0;
      &::after {
        background-color: $primary;
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
      }
      &:nth-of-type(2) {
        transition: 0.3s;
      }
    }
    &--active {
      span:first-child {
        transform: rotate(45deg);
      }
      span:nth-of-type(2) {
        opacity: 0;
        transition: 0.1s;
      }
      span:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
  .search-opener {
    left: calc(100vw - 141px);
    .btn {
      width: 24px;
      transform: translate(-2px, -2px);
      padding: 0px;
      min-height: auto;
      border: 0px;
      min-width: 0px !important;
    }
  }
  .context-menu {
    left: 0% !important;
    transform: translate(0%) !important;
    top: 0;
    z-index: 1000;
    a {
      margin-bottom: 0px;
      display: flex !important;
      align-items: center;
    }
  }
  .search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    transform: translateY(-100%);
    background-color: white;
    max-width: none;
    z-index: 1000;
    height: 50px;
    margin: 0px !important;
    transition: 0.14s;
    .btn {
      width: 61px;
      min-width: 0px !important;
    }
    &.open {
      top: $header-height-mobile;
      transform: translateY(0%);
      transition: 0.7s;
    }
  }
}
@media (max-height: 675px) {
  .header__nav--lower ul a,
  .header__nav--upper a {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 47px;
    padding-top: 3px;
    padding-bottom: 3px;
    height: 39px;
  }
  .header__nav--upper {
    padding-bottom: 32px;
  }
  .header__nav {
    &--lower {
      min-height: calc(55% - 43px);
    }
    &--upper {
      min-height: calc(45% - 43px);
    }
  }
}

@media (max-width: 510px) {
  .header__nav {
    margin-right: 0px;
  }
}
@media (min-width: 992px) {
  .open-context {
    width: 200%;
    & ~ div {
      display: none;
    }
    &:hover ~ div {
      display: flex;
    }
    &.first:hover ~ div {
      display: flex;
      > div {
        display: flex !important;
      }
    }
  }
  .header__nav--lower > a:hover {
    .context-menu {
      display: flex;
    }
  }

  .menu-1 {
    display: none;
    top: calc(100% + 12px);
    &:hover {
      display: flex;
    }
  }

  .menu-3 {
    display: none;
    border-top: 1px solid white;
    top: -1px !important;
    &:hover {
      display: flex;
    }
  }
  .menu-4 {
    display: none;
    border-top: 1px solid white;
    top: -1px !important;

    &:hover {
      display: flex;
    }
  }
  .context-menu {
    width: 250px;
    border-top: 1px solid white;
    .menu-item {
      width: 250px !important;
      position: relative;
      &:after {
        width: 250px !important;
      }
    }
  }
  .context-menu:hover {
    display: flex;
    .menu-2 {
      display: flex;
    }
  }
  .menu-1,
  .menu-2,
  .menu-3,
  .menu-4 {
    a {
      flex: 1;
      width: 100%;
    }
    &:hover {
      display: flex;
    }
  }
  .context-menu-opener {
    display: none;
  }
  //  .menu-item{
  //   border-right: 1px solid gray;
  //  }
}