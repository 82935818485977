body {
  width: 100vw;
  overflow-x: hidden;
}
main {
  padding-top: calc(#{$header-height} - 1px);
}
section {
  overflow-x: clip;
  position: relative;
}
.btn {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  font-size: 12px;
  text-transform: uppercase;
  &--small {
    width: 162px;
  }
  &--xs {
    width: 118px;
  }
  &--low {
    height: 27px;
  }
}
.row {
  > div * {
    --bs-gutter-x: 0.89rem;
  }
}
.upload {
  &__load {
    * {
      cursor: pointer;
    }
    input {
      width: 220px;
      opacity: 0;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    img {
      position: relative;
      top: 2px;
    }
  }
}

.news__pagination {
  a {
    transition: 0.3s;
  }
  a:first-of-type:hover {
    transform: translateX(-2px);
  }
  a:last-of-type:hover {
    transform: translateX(2px);
  }
}
.form-control {
  border-bottom: 1px solid $primary;
  border-radius: 0px;
  max-width: 290px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray-color !important;
  opacity: 1; /* Firefox */
}
.form-control::placeholder {
  color: $gray-color !important;
  opacity: 1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-color !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray-color !important;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.swiper-buttons {
  .swiper-button-next,
  .swiper-button-prev {
    position: static;
    margin-top: 0px;
    height: 40px;
    color: $primary;
    transition: 0.3s;
    &::after {
      font-size: 40px;
    }
  }
  .swiper-button-prev:hover {
    transform: translateX(-2px);
  }
  .swiper-button-next:hover {
    transform: translateX(2px);
  }
}
.container {
  &--primary {
    max-width: 1164px;
    .absolute__half {
      width: 43vw;
      &--right {
        left: 58%;
      }
      &--left {
        right: 58%;
      }
    }
  }
}
label {
  p {
    display: inline;
  }
}
.absolute__half-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner {
  position: relative;
  .container {
    position: relative;
  }

  &__main {
    height: 740px;
    max-height: 76vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &__secondary {
    height: 575px;
    max-height: 76vh;
    .container {
      height: 100%;
    }
  }
  &__items {
    margin-top: 40px;
  }
  &__content {
    background-color: white;
    z-index: 1;
    position: absolute;
    width: 574px;
    padding-left: 56px;
    padding-right: 45px;
    padding: 47px 45px 44px 56px;
    border-bottom: 1px solid #eeeeee;
    $top: -56px;
    &--bottom-left {
      bottom: -50px;
      left: 8px;
    }
    &--bottom-right {
      bottom: -50px;
      right: 8px;
    }
    &--top-left {
      top: $top;
      left: 8px;
    }
    &--top-right {
      top: $top;
      right: 8px;
    }
    p {
      // max-height: 7.22rem;
      margin-bottom: 0px;
      overflow: hidden;
    }
    &::after {
      content: "";
      top: 0px;
      height: 20px;
      width: 100%;
      background-image: url("../img/pasek.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
    }
    h1,
    h3 {
      margin-bottom: 24px;
    }
  }
}

.sparralax-static {
  .parallax-window > div:nth-child(1) {
    position: static;
    height: 100%;
    transform: translate(0);
  }
  Element .parallax-window {
    height: 100%;
  }
  Element .parallax-window > div:nth-child(1) > img:nth-child(1) {
    height: 100%;
    object-fit: cover;
  }
}
.absolute__equal-parent {
  .simpleParallax {
    height: 100%;
    img {
      min-height: 100%;
    }
  }
}
.brand {
  max-height: 142px;
  overflow: hidden;
  padding: 27px 43px 30px 54px;
  transition: 0.7s;
  position: relative;
  &::after {
    content: "";
    background-image: url("../img/next.svg");
    background-position: center;
    background-size: cover;
    position: absolute;
    right: 44px;
    height: 44px;
    width: 22px;
    top: 50%;
    transition: 0.3s;
    transform: translateY(-50%);
    filter: brightness(0%) grayscale(100%) invert(100%);
  }
  &:hover,
  &.active {
    overflow: visible;
    max-height: 1000px;
    transition: 1.5s;

    img {
      opacity: 0px;
    }
    &::after {
      opacity: 0px;
    }
    .on-hover {
      * {
        opacity: 1;
        // transition: 0.4s;
        transition-delay: 1.5s;
      }
    }
  }
  img {
    height: 63px;
    transition: 0.3s;
    position: absolute;
    left: 112px;
    top: 50%;
    transform: translateY(-50%);
  }
  .on-hover {
    transition: 0.3s;
    .btn-info {
      transition: 0.3s;
    }
    * {
      opacity: 0;
    }
  }
  &__content {
    p {
      color: white;
    }
  }
}
.check-opener {
  bottom: 0.14rem;
  cursor: pointer;
  img {
    width: 0.75rem;
    height: 0.75rem;
  }
}
.outside-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.5s ease-out;
  &.opened {
    grid-template-rows: 1fr;
    margin-top: 0.2rem;
  }
  .inside-wrapper {
    overflow: hidden;
  }
}
.additional-law {
  font-size: 0.9em;
  // filter: grayscale(50%);
  color: #9a9a9a;
}
.blog {
  &__cards {
    > div {
      > div,
      > p {
        overflow: hidden;
      }
      > div:nth-of-type(3) {
        max-height: 2.833rem;
        padding-right: 16px;
      }
      p {
        max-height: 5.61rem;
        padding-right: 16px;
      }
    }
    &--stairs {
      & > div:nth-child(3n - 1) {
        padding-top: 50px;
      }
      & > div:nth-child(3n) {
        padding-top: 100px;
      }
    }
  }
  &__header {
    height: 2.26rem;
    overflow: hidden;
  }
  &__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

    -webkit-line-clamp: 4;
    height: 5.72rem;
    line-height: 1.44rem;
    overflow: hidden;
    &--big {
      line-height: 1.66rem;
      max-height: 5.11rem;
      overflow: hidden;
    }
  }
}

.shapes {
  position: absolute;
  z-index: -1;
  $x: -85px;
  transform: translateY(-50%);
  top: 50%;
  left: $x;
  &__top {
    top: 0%;
  }
  &__top--middle {
    top: 25%;
  }
  &__center {
    top: 50%;
  }
  &__bottom--middle {
    top: 100%;
  }
  &__bottom {
    top: 100%;
  }
  &__right {
    left: initial;
    right: $x;
  }
  .simpleParallax {
    overflow: visible !important;
    img {
      width: calc((449 / 280) * 100px);
    }
  }
}
.bar,
.newsletter {
  h4 {
    font-weight: 400;
  }
}

.parralax {
  height: 50vh;
  // &__item{
  //   height: 100%;
  // }
}
.parallax-window,
.parralax {
  height: 575px;
  // padding-bottom: 575px;
  overflow: clip;
  position: relative;
  width: 100vw;

  .simpleParallax {
    position: absolute;
    display: flex;
    align-items: center;
    height: 575px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  &--small {
    height: 415px;
    > .container {
      height: 100%;
    }
  }
  > .container {
    height: 100%;
  }
}
.banner.parallax-window {
  overflow-y: visible;
}
.sparallax-static {
  height: 100%;
  * {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
.parallax-window {
  position: absolute;
}
.trainings {
  &__vertically {
    background-image: url("../img/gray-shapes.jpg");
    background-size: cover;
    background-position: center;
    height: 100%;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    h2 {
      flex-basis: 100%;
      display: flex;
      margin-bottom: 0px;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
    }
  }
  &__content {
    padding: 4.22rem 12.7rem 2rem 6.55rem;
    .btn {
      margin-top: 2rem;
    }
  }
}
footer {
  ul {
    li {
      list-style: none;
      margin-bottom: 4px;
      a {
        font-size: 0.66rem;
        letter-spacing: 0.6px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
  p {
    margin-bottom: 3px;
    a {
      color: black;
      text-decoration: none;
    }
  }
  .logo {
    img {
      height: 101px;
      width: 107px;
    }
  }
  .adress {
    max-width: 220px;
  }
  .social {
    width: 133px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 21px;
    margin-top: 22px;
  }
}
.newsletter {
  .btn {
    width: 118px;
  }
}
.job {
  &__offers {
    & > div:last-of-type {
      border-bottom: 1px solid #dcdcdc;
    }
  }
  &__offer {
    height: 96px;
    border-top: 1px solid #dcdcdc;
  }
  &__city,
  &__mode,
  &__date {
    span {
      position: relative;
      font-size: 1rem;
      color: black;
      &::after {
        position: absolute;
        content: "";
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        background-image: url("../img/city.svg");
        background-size: contain;
        background-repeat: no-repeat;
        height: 20px;
        width: 16px;
      }
    }
  }
  &__mode {
    span {
      &::after {
        background-image: url("../img/home.svg");
      }
    }
    &.hybrid {
      span {
        &::after {
          background-image: url("../img/globe.svg");
        }
      }
    }
  }
  &__date {
    span {
      &::after {
        top: 53%;
        background-image: url("../img/date.svg");
      }
    }
  }
}
.persons {
  &__image {
    border-right: 1px solid $primary;
    font-weight: 600;
    .zoom {
      width: 143px;
      height: 143px;
    }
    &--big {
      .zoom {
        width: 219px;
        height: 219px;
      }
    }
    img {
      object-position: top !important;
    }
  }
  &__text {
    // height: 11.44rem;
    overflow: hidden;
    padding-top: 10px;
  }
}
.logos {
  max-width: 970px;
  img {
    flex-basis: 25%;
    max-width: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      justify-content: start;
    }
  }
  .funds {
    img {
      height: calc((68 / #{$rem}) * 1rem);
    }
  }
  .pol,
  .woj,
  .union {
    img {
      height: calc((53 / #{$rem}) * 1rem);
    }
  }
}
#map {
  width: 100%;
  height: 150px;
  filter: grayscale(100);
  top: 0px;
}
.map {
  #map {
    height: 100% !important;
  }
}
@media (min-width: 576px) {
  .absolute {
    position: absolute;
    height: 100%;
    .zoom {
      height: 100%;
    }
    &__half {
      width: 51vw;

      &--left {
        right: 50%;
      }
      &--right {
        left: 50%;
      }
    }
    &__equal-parent {
      width: 120%;
      &--left {
        right: 100%;
      }
    }
    &.parallax-window {
      min-height: initial;
    }
  }
}
@media (min-width: 992.1px) {
  .trainings {
    &__vertically {
      h2 {
        writing-mode: tb-rl;
        transform: rotate(180deg);
      }
    }
  }
  .brand-cols {
    min-height: 142px;
    position: relative;

    > div {
      position: absolute;
      width: 50%;
      &:first-of-type {
        left: 0px;
      }
      &:last-of-type {
        left: 50%;
      }
    }
  }
  .banner__secondary {
    height: 497px;
  }
}
@media (min-width: 1200px) {
  .banner__secondary {
    height: 550px;
  }
}
@media (min-width: 1440px) {
  .banner__secondary {
    height: 575px;
  }
}
@media (max-width: 1439.98px) {
  .parallax-window {
    height: 550px;
    &--small {
      height: 350px;
    }
  }
  .trainings {
    .low-img {
      height: 350px;
    }
  }
}
@media (max-width: 1199.98px) {
  .parallax-window {
    height: 500px;
    &--small {
      height: 300px;
      .container {
        height: 100%;
      }
    }
    .container {
      height: 100%;
    }
  }
  .trainings {
    .low-img {
      height: 300px;
    }
  }
}
@media (max-width: 991.9px) {
  .true-parallax {
    height: 100% !important;
  }
  .unclickable {
    pointer-events: none;
  }
  main {
    padding-top: $header-height-mobile;
  }
  .banner {
    height: calc(100vh - #{$header-height-mobile} - #{$mobile-toolbar});
    .container {
      height: 100%;
    }
    &__main {
      max-height: initial;
      img {
        position: absolute;
      }
    }
    &__content {
      top: inherit;
      bottom: 0px;
    }
    .simpleParallax {
      min-height: 100%;
    }
  }
  .logos {
    > div {
      &:first-of-type {
        justify-content: center;
      }
    }
  }
  .parallax-window {
    min-height: 400px;
  }
  .blog {
    &__cards {
      &--stairs {
        & > div:nth-child(3n - 1),
        & > div:nth-child(3n) {
          padding-top: 30px;
        }
      }
    }
  }
  .trainings {
    &__vertically {
      position: static;
      height: 252px;
      flex-direction: row;
      padding-bottom: 0px;
      justify-content: space-around;
      h2 {
        writing-mode: initial;
        flex: initial;
      }
    }
  }
  .parallax-window.absolute {
    display: none;
  }
  .job {
    h2 {
      text-align: center;
    }
    h5 {
      text-align: center;
    }
    &__offer {
      width: 80%;
      min-width: 300px;
      margin: auto;
      min-height: 96px;
      height: initial;
      justify-content: center;
      h5 {
        font-size: 1.07rem;
      }
    }
    &__city {
      margin-left: 30px;
    }
  }
  .persons {
    &__image {
      margin-bottom: 25px;
      border-right: 0px;
      &--mobile-bigger {
        .zoom {
          width: 195px;
          height: 195px;
        }
      }
    }
    &__text {
      text-align: center;
      // height: 194px;
    }
    &__name {
      font-size: 1.3rem;
      line-height: 1;
    }
  }
}
@media (max-width: 767.9px) {
  .brand {
    padding: 30px;
    img {
      left: 44px;
    }
  }
  .parallax-window {
    min-height: 320px;
  }
  .bar {
    text-align: center;
    .checkbox-d {
      text-align: left;
    }
  }
  .upload {
    &__load {
      input {
        width: 135px;
      }
    }
    &__main {
      width: 328px;
      margin: auto;
    }
  }
}
@media (max-width: 575.9px) {
  .banner {
    &__content {
      width: 100%;
      padding: 35px 17px 22px 10px;
      &.mobile-0 {
        border-bottom: 0px;
      }
      &--bottom-left {
        bottom: 0px;
        left: 0px;
      }
      &--bottom-right {
        bottom: 0px;
        right: 0px;
      }
      &--top-left {
        top: initial;
        bottom: 0px;
        left: 0px;
      }
      &--top-right {
        top: initial;
        bottom: 0px;
        right: 0px;
      }
      h1,
      h3 {
        margin-bottom: 15px;
      }
      p {
        margin-bottom: 20px;
      }
    }
  }
  .trainings {
    &__vertically {
      flex-direction: column;
      h2 {
        text-align: center;
      }
    }
    &__content {
      padding: 2rem 3rem 1rem 1.25rem;
    }
  }
  .btn {
    min-width: 150px;
  }
  .container {
    &--primary {
      .absolute__half {
        width: initial;
      }
    }
  }
  .absolute__half {
    order: -1;
  }
  .persons {
    &__header {
      text-align: center;
    }
  }
  .email {
    width: 300px;
    text-align: center;
  }
  .second-section-distance {
    section:nth-of-type(2) {
      padding-top: 150px;
    }
  }
}
