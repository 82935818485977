.my-slick,
.my-slick-2,
.my-slick-5 {
  .slick-list {
    max-width: 1164px;
    margin: auto;
    cursor: pointer;
  }
}
.gallery-slick {
  .slick-arrow-prev {
    left: 65px;
  }
  .slick-arrow-next {
    right: 65px;
  }
}
.slick-arrow-prev.big-click,
.slick-arrow-next.big-click {
  &::before {
    width: 500%;
    height: 300%;
    transform: translate(-41%, -32%);
  }
}
.my-slick-2,
.my-slick-5 {
  .slick-slide {
    padding-left: 8px;
    padding-right: 8px;
  }
}
.my-slick {
  position: relative;
  margin-top: 305px;

  .slick-slide {
    background-color: #f6f6f6;
    padding: 58px 60px 57px 50px;
    display: flex !important;
    gap: 70px;
    h3 {
      margin-bottom: 23px;
      line-height: 42px;
    }
    & > div {
      flex-basis: 50%;
    }
    .slick-slide-header {
      line-height: 30px;
      margin-bottom: 26px;
      font-weight: 500;
    }
    .slick-slide-text {
      // max-height: 182px;
      font-size: 17px;
      color: black;
      overflow: hidden;
    }
    & > div:last-of-type {
      // max-height: 240px;
      margin: auto;
    }
  }
  .slick-dots {
    position: absolute;
    top: -229px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 20px;
    right: 0px;
    padding-right: 50px;
    &::after,
    &::before {
      content: "";
      position: absolute;
      right: -4px;
      height: 1px;
      width: 18px;
      background-color: #c0dbeb;
      transform: rotate(45deg);
      bottom: -1px;
    }
    &::after {
      transform: rotate(135deg) translateY(-9px) translateX(10px);
    }
    li {
      list-style: none;
      button:hover {
        font-weight: 500;
      }
      $distance: 4px;
      &:nth-of-type(2n-1) {
        button {
          top: -$distance;
          &::before {
            bottom: -$distance;
          }
        }
      }
      // $distance: 4px;
      &:nth-of-type(2n) {
        transform: translateY(61px);
        button {
          top: $distance;
          &::before {
            bottom: initial;
            top: -2px;
          }
        }
      }
      &:first-of-type {
        position: relative;
      }

      &.odd,
      &.even {
        &::before,
        &::after {
          content: "";
          width: 100vw;
          right: 11px;
          position: absolute;
          bottom: -21px;
          height: 1px;
          background-color: #c0dbeb;
          z-index: -1;
        }
        &::after {
          bottom: 5px;
        }
      }
      &.even {
        &::before,
        &::after {
          top: -17px;
          height: 1px;
          background-color: #c0dbeb;
          z-index: -1;
          right: -39px;
        }
        &::after {
          top: 8px;
        }
      }
    }
    button {
      position: relative;
      width: 58px;
      height: 50px;
      background-color: transparent;
      color: $primary;
      border: 0px;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        background-color: #c0dbeb;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        transition: 0.15s;
        z-index: 1;
      }

      &:hover {
        &::before {
          background-color: #002039;
        }
      }
    }
    li.slick-active {
      button {
        &::after {
          opacity: 1;
          font-weight: 500;
        }
        &::before {
          background-color: #002039;
        }
      }
    }
  }
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 31px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
  transition: 0.3s;
  cursor: pointer;
  img {
    height: 31px;
    width: 15px;
  }
}
.slick-arrow-prev {
  background-image: url(../img/prev.svg);
  left: 43px;
  &:hover {
    transform: translateY(-50%) translateX(-2px);
  }
}
.slick-arrow-next {
  background-image: url(../img/next.svg);
  right: 43px;
  &:hover {
    transform: translateY(-50%) translateX(2px);
  }
}
.history {
  .slick-arrow-next,
  .slick-arrow-prev {
    background-image: none;
  }
}
$arrows-position: 185px;
.persons {
  .slick-arrow-next {
    right: $arrows-position;
  }
  .slick-arrow-prev {
    left: $arrows-position;
  }
  .my-slick-3 {
    .slick-list {
      max-width: 928px;
      margin: auto;
      .slick-slide {
        cursor: pointer;
        // height: 294px;
        overflow-y: clip;
        background-color: #f6f6f6;
        padding: 54px 40px 65px 40px;
      }
    }
  }
}
@media (max-width: 1439.99px) {
  .my-slick {
    .slick-list {
      max-width: 1000px;
    }
  }
  .my-slick-2,
  .my-slick-5 {
    .slick-list {
      max-width: 83%;
    }
  }
  .persons {
    .slick-arrow-next {
      right: calc(#{$arrows-position} * 0.1);
    }
    .slick-arrow-prev {
      left: calc(#{$arrows-position} * 0.1);
    }
  }
}
@media (max-width: 1199.99px) {
  .my-slick {
    .slick-list {
      max-width: 830px;
    }
  }
  .my-slick-2,
  .my-slick-5 {
    .slick-list {
      max-width: 80%;
    }
  }
}
@media (max-width: 991.99px) {
  .history-slider {
    .slick-slide {
      align-content: start;
    }
  }
  .my-slick {
    margin-top: 140px;
    .slick-list {
      max-width: 585px;
      .slick-slide {
        flex-wrap: wrap;
        & > div {
          flex-basis: 100%;
          &:first-of-type {
            order: 2;
          }
        }
      }
    }
    .slick-dots {
      top: -125px;
    }
  }
  .persons {
    .my-slick-3 {
      .slick-list {
        max-width: 430px;
        // .slick-slide {
        //   height: 587px;
        // }
      }
    }
  }
}
@media (max-width: 767.9px) {
  .history {
    .slick-arrow {
      top: 36%;
    }
  }
  .my-slick {
    margin-top: 0px;
  }
  .my-slick-2,
  .my-slick-5 {
    .slick-list {
      max-width: 75%;
    }
  }
}
@media (max-width: 575.99px) {
  // .history-slider {
  //   .slick-list {
  //     .slick-slide {
  //       padding: 31px 9px 83px 9px;
  //       .slick-slide-header {
  //         margin-bottom: 0px;
  //         h3 {
  //           margin-bottom: 0px;
  //         }
  //       }
  //     }
  //   }
  // }
  .history {
    .slick-arrow {
      $sizes: 50px;
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: $sizes;
        height: $sizes;
        background-color: #f6f6f6;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.5;
        z-index: -1;
      }
    }

    .slick-arrow-next {
      right: 40px;
    }
    .slick-arrow-prev {
      left: 40px;
    }
    .history-slider {
      h3 {
        text-align: center;
      }
    }
  }
  .persons {
    .slick-arrow-next {
      right: calc(#{$arrows-position} * 0.2);
    }
    .slick-arrow-prev {
      left: calc(#{$arrows-position} * 0.2);
    }
  }
  .my-slick {
    .slick-list {
      .slick-slide {
        gap: 20px;
        padding: 38px 40px 83px 40px;
      }
    }
    .slick-dots button {
      width: 33px;
      padding: 0px;
    }
  }
  .slick-arrow {
    top: calc(100% - 50px);
  }
  .slick-arrow-prev {
    left: 30px;
  }
  .slick-arrow-next {
    right: 37px;
  }
  .persons {
    .slick-arrow {
      top: calc(100% - 39px);
    }
    .my-slick-3 {
      .slick-list {
        max-width: 99%;
        margin-bottom: 10px;
        // .slick-slide {
        // height: 601px;
        // }
      }
    }
  }

  .gallery-slick {
    $dist: 10px;
    .slick-arrow-next,
    .slick-arrow-prev {
      top: 50%;
    }
    .slick-arrow-next {
      right: $dist;
    }
    .slick-arrow-prev {
      left: $dist;
    }
  }
}
// @media (max-width: 430px) {
//   .my-slick {
//     .slick-dots button {
//       width: 43px;
//       font-size: 14px;
//     }
//   }
// }
