.form-box1 {
  width: 470px;
}

.img-bg {
  background-image: url("../img/farba3.jpg");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  min-height: 100vh;
  height: 100%;
}

.forgot {
  font-size: 0.7em;
  font-style: italic;
}

.account-login {
  width: 220px;
  font-size: 0.7em;
  font-style: italic;
}

.form-control {
  max-width: 100%;
}

.checkbox-l {
  display: inline-flex;
  align-items: start;
  font-size: 12px;
}
.checkbox-i {
  margin-right: 10px;
  position: relative;
  top: 3px;
}
.checkbox-d {
  max-width: 450px;
}

// MODAL///////////////////////////////////////

.modal-body {
  width: 360px;
}
.modal-contact {
  display: inline-block;
  margin-left: 35px;
}

.modal-content-registration {
  height: 672px;
  padding: 0px 30px 36px 42px;
  button {
    transform: translateX(5px);
  }
  .modal-header {
    margin-top: -5px;
  }
  .btn-close {
    margin-top: 14px;
  }
}
.modal-box-client {
  border-top: 1px solid black;
  padding: 20px 0px 30px;
  img {
    margin-right: 16px;
  }
}

.wrapper-1 {
  overflow: auto;
}

.modal-header {
  margin-top: 30px;
  margin-bottom: -10px;
  h4 {
    margin-bottom: 0px;
  }
}

.btn-close {
  font-size: 10px;
}

.modal-border-img {
  width: calc(100% + 72px);

  height: 45px;
  background-image: url("../img/farba.jpg");
  background-position: center;
  background-size: cover;
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  margin-left: -42px;
}
.modal-content-sharp {
  border-radius: unset;
}
.btn-close-modal {
  margin-top: 15px;
}
// modal content////
@media (max-width: 576px) {
  .modal-content-registration {
    padding: 0px 10px;
    .btn-close-modal {
      margin-top: 0px;
    }
    .modal-contact {
      margin-left: 0px;
    }
    .modal-body {
      width: auto;
    }
    .modal-border-img {
      width: calc(100% + 21px);
      margin-left: -10px;
    }
  }
}
@media (max-width: 320px) {
  .form-box1 {
    width: 320px;
  }

  h3 {
    font-size: 20px;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  .box-phone {
    min-height: 100vh;
  }
  .form-box1 {
    width: 310px;
  }
  h3 {
    font-size: 20px;
  }
}

@media (min-width: 375px) and (max-width: 576px) {
  .box-phone {
    min-height: 100vh;
  }
  .form-box1 {
    width: 360px;
  }

  h3 {
    font-size: 25px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .box-phone {
    min-height: 100vh;
  }

  .form-box1 {
    width: 470px;
  }
}

@media (min-width: 992px) {
  .modal-header-save {
    padding: 20px 55px 0;
  }
}
