.news {
  .blog__card {
    margin-bottom: 77px;
    .text-decoration-none {
      max-height: calc((40 / #{$rem}) * 1rem);
      overflow: hidden;
    }
    &--big {
      .text-decoration-none {
        max-height: initial;
        overflow: hidden;
      }
    }
  }
  &__pagination {
    * {
      width: auto;
    }
    input {
      width: 72px;
      height: 40px;
      border: 1px solid #9a9a9a !important;
      font-size: 17px;
      color: #9a9a9a;
      text-align: center;
    }
  }
}
.gain {
  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
      > div {
        > div {
          margin-bottom: 26px;
        }
      }
      > div:nth-of-type(1) {
        // .zoom {
        //   position: relative;
        //   top: 7px;
        // }
        img {
          max-width: 147px;
        }
      }
      > div:nth-of-type(2) {
        // .zoom {
        //   position: relative;
        //   bottom: 20px;
        // }
        img {
          max-width: 124px;
        }
      }
      > div:nth-of-type(3) {
        img {
          max-width: 106px;
        }
      }
      > div:nth-of-type(4) {
        // .zoom {
        //   position: relative;
        //   top: 20px;
        // }
        img {
          max-width: 110px;
        }
      }
    }
    .zoom {
      width: 80%;
    }
    img {
      max-height: 134px;
      object-fit: contain;
      object-position: center;
    }
  }
}
.sticky {
  position: sticky;
  top: 137px;
  &__content {
    padding: 51px 0px 72px 0px;
    background-color: #f6f6f6;
  }
  &__text {
    padding-right: 118px;
    padding-left: 45px;
  }
}
.offer {
  &__nav {
    div {
      font-size: 0.66rem;
      font-weight: 700;
      text-transform: uppercase;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: none;
      }
    }
  }
  &__details {
    max-width: 310px;
    > div {
      padding-left: 48px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        left: 0px;
        top: calc((3 / #{$rem}) * 1rem);
        height: 20px;
        width: 20px;
        background-size: contain;
        background-image: url("../img/globe.svg");
        background-repeat: no-repeat;
      }
    }
  }
  &__iframe-wrapper {
    width: calc((338 / #{$rem}) * 1rem);
    display: flex;
  }
  &__mode.remote::after {
    background-image: url("../img/home.svg");
  }
  &__date::after {
    background-image: url("../img/date.svg") !important;
  }
  &__localization {
    &::after {
      background-image: url("../img/city.svg") !important;
    }
  }
  &__time {
    &::after {
      background-image: url("../img/time.svg") !important;
    }
  }
}
.checklist {
  padding-left: 0px;
  li {
    padding-left: 49px;
    color: black;
    margin-bottom: .4rem;
    font-size: 0.94rem;
    list-style: none;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      left: 0px;
      background-image: url("../img/check.svg");
      top: 0%;
      transform: translateY(15%);
      height: 20px;
      width: 20px;
      object-fit: contain;
      background-repeat: no-repeat;
    }
  }
}
.back {
  padding-left: 30px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 8px;
    background-image: url("../img/prev.svg");
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.bg-after {
  position: absolute;
  &::after {
    position: absolute;
    width: 100vh;
    height: 100%;
    background-color: #eeeeee;
    content: "";
    left: calc(0% - 100vh);
    top: 0px;
  }
}
.contact {
  &__adresses {
    p {
      margin-bottom: 0px;
      line-height: 1.44rem;
    }
    a {
      text-decoration: none;
    }
  }
  &__form {
    max-width: 574px;
    margin-left: auto;
    background-color: #f6f6f6;
    padding: 39px 53px 57px 51px;
    $font-size: 0.94rem;
    $padding: 11px 0px;
    .form-select {
      border-bottom: 1px solid $primary !important;
      font-size: $font-size;
      padding: $padding;
    }
    input {
      font-size: $font-size;
      padding: $padding;
    }
    button {
      margin-left: 13.5px;
    }
  }
  &__modals {
    margin-top: 82px;
    button {
      width: 100%;
      margin-bottom: 7px;
    }
  }
  .form-check-input {
    border: 1px solid $primary;
    background-color: transparent;
    padding: 0px;
    margin-top: 4px;
  }
  .form-check-input:checked[type="checkbox"] {
    background-color: #9a9a9a;
    border-color: #9a9a9a;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23eeeeee' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");
  }
  textarea {
    border: 1px solid $primary !important;
    padding: 15px;
    resize: none;
  }
  .form-control::placeholder {
    font-size: 0.94rem;
  }
}

.product__attachment {
  img {
    position: relative;
    bottom: 4px;
  }
}

.downloads {
  .accordions-toggler {
    position: absolute;
    z-index: 12;
    right: 0px;
    top: calc((29 / #{$rem}) * 1rem);
  }
  .accordion-header {
    margin-bottom: calc((36 / #{$rem}) * 1rem);
  }
  .accordion-button::after {
    filter: brightness(0);
    position: relative;
    top: 0.33rem;
  }
  .accordion-item {
    * {
      color: black;
    }
  }
}
@media (max-width: 1439px) {
  .sticky {
    &__text {
      padding-right: 61px;
    }
  }
}
@media (max-width: 991.9px) {
  .sticky {
    &__text {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  .contact {
    text-align: center;
    &__modals {
      margin-top: 30px;
      button {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media (max-width: 767px) {
  .sticky {
    &__content {
      max-width: 387px;
      margin: auto;
    }
    &__text {
      padding-right: 118px;
      padding-left: 45px;
    }
  }
}
@media (max-width: 575.9px) {
  .offer {
    &__iframe-wrapper {
      width: calc(100vw - (var(--bs-gutter-x)));
    }
  }
  .offer__details > div::after {
    top: 0;
  }
}
@media (min-width: 768px) {
  .gain {
    &__item {
      height: 220px;
    }
  }
}

@media (max-width: 767.9px) {
  .gain {
    &__item {
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 575.9px) {
  .downloads .accordions-toggler {
    top: calc((-37 / 16) * 1rem);
  }
}
