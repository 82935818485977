.map {
  &__changer {
    width: 220px;
    height: 46.42px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 9px;
    padding-right: 24px;

    img {
      max-width: 81px;
    }
    label {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      transition: 0.3s;
      &::after {
        position: absolute;
        content: "";
        left: -54px;
        right: -24px;
        top: -6px;
        bottom: -7px;
        cursor: pointer;
      }
    }
    input {
      margin: auto 0px !important;
    }
    &--yellow {
      background-color: #ea9f08;
      .form-check-input:checked[type="radio"],
      .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ea9f08' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");

      }
    }
    &--blue {
      background-color: #005d9f;
      .form-check-input:checked[type="radio"],
      .form-check-input:checked[type="checkbox"] {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23005d9f' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");

      }
    }
    &--gray {
      background-color: transparent;
      border: 1px solid #707070;
      .form-check-input{
        border: 1px solid #707070;
      }
      .form-check-input:checked[type="radio"],
      .form-check-input:checked[type="checkbox"] {
        background-color: #9a9a9a;
        border-color: #9a9a9a;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23eeeeee' stroke-linecap='square' stroke-linejoin='square' stroke-width='3' d='m3 10 5 5 10-10'/%3e%3c/svg%3e");

      }
    }
  }
  &__images {
    max-width: 1126px;
    margin: auto;
  }
  &__image {
    visibility: hidden;
    opacity: 0;
    transition: 2s;
    &.active {
      visibility: visible;
      opacity: 1;
      transition: 2s;
    }
  }
}
