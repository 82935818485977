.slider-box {
  // height: 900px;
  width: 100%;
  padding: calc((48 / #{$rem}) * 1rem) calc((118 / #{$rem}) * 1rem)
    calc((46 / #{$rem}) * 1rem) calc((118 / #{$rem}) * 1rem);
  .slick-slide {
    width: 100%;
  }
  h3,
  p {
    color: transparent;
  }
  $position: 30px;
  .slick-arrow-next {
    right: $position;
  }
  .slick-arrow-prev {
    left: $position;
  }
}
.modal-slider {
  .modal-content {
    visibility: hidden;
    opacity: 0;
    $transition: 0.3s;
    transition-delay: $transition;
    transition-duration: $transition;
    &.content-on {
      visibility: visible;
      opacity: 1;
    }
  }
}
.modal-slider.show {
  .add-visibility {
    $transition: 0.3s;
    h3 {
      color: $primary;
      transition-delay: $transition;
      transition-duration: $transition;
    }
    p {
      transition-delay: $transition;
      transition-duration: $transition;
      color: black;
    }
  }
}
.modal-content {
  $height: 800px;
  max-height: $height !important;
  height: $height !important;
}
.modal-slider.show {
  .modal-content {
    max-height: none !important;
    height: initial !important;
  }
}
.modal-slider {
  --bs-modal-width: 1000px;
}
.btn-close-slider {
  margin-top: 30px;
  margin-right: 30px;
}
@media (max-width: 1200px) {
  .slider-box {
    // height: 900px;
    padding: calc((38 / #{$rem}) * 1rem) calc((70 / #{$rem}) * 1rem)
      calc((38 / #{$rem}) * 1rem) calc((70 / #{$rem}) * 1rem);
  }
}
@media (max-width: 575.9px) {
  .slider-box {
    // height: 900px;
    padding: 20px 15px 60px;
    $position: 15px;
    .slick-arrow {
      top: initial;
      bottom: 5px;
    }
    .slick-arrow-next {
      right: $position;
    }
    .slick-arrow-prev {
      left: $position;
    }
  }
}
